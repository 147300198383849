<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right"></div>
    </div>
    <div
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Lohnarten - Verknüpfung zu externen Programmen
    </div>

    <div class="xColumn3">
      <strong style="font-size: 16px">Häufig verwendete Lohnarten</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Bezeichnung</th>
          <th class="documentsTh">Externe ID</th>
          <th class="documentsTh" style="text-align: center">Bearbeiten</th>
        </tr>
        <tr v-for="wageType in wTMMain" v-bind:key="'wageTypesTr' + wageType.enumId">
          <td class="documentsTd">
            <strong>{{ wageType.enumValueGER }}</strong>
          </td>
          <td class="documentsTd">
            <strong>{{ wageType.externalId }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Externe ID bearbeiten'"
              icon="fa-edit"
              v-on:click="openEditWageType(wageType)"
            />
          </td>
        </tr>
      </table>
    </div>

    <div class="xColumn3">
      <strong style="font-size: 16px">Alle anderen Lohnarten</strong>
      <br />

      <table class="table table-striped table-borderless" style="margin-top: 7px">
        <tr>
          <th class="documentsTh">Bezeichnung</th>
          <th class="documentsTh">Externe ID</th>
          <th class="documentsTh" style="text-align: center">Bearbeiten</th>
        </tr>
        <tr v-for="wageType in wTMNMain" v-bind:key="'wageTypesTr' + wageType.enumId">
          <td class="documentsTd">
            <strong>{{ wageType.enumValueGER }}</strong>
          </td>
          <td class="documentsTd">
            <strong>{{ wageType.externalId }}</strong>
          </td>
          <td class="documentsTd" style="text-align: center">
            <buttonc
              v-tooltip="'Externe ID bearbeiten'"
              icon="fa-edit"
              v-on:click="openEditWageType(wageType)"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import button from "../uicomponents/button.vue";
export default {
  components: { button },
  name: "WageTypes",
  data() {
    return {
      loading: true,
      saves: false,

      wageTypesMapping: [],
    };
  },
  created: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  mounted: function () {},
  computed: {
    ...mapGetters(["companyId"]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        click: () => {
          view.refresh();
        },
        tooltip: "Daten neu laden",
      });
      /*
      state.buttons.push({
        type: "block",
        icon: "fa-wand-magic",
        click: ($event) => {
          view.fillDefaultValues($event);
        },
        tooltip: "BMD Standard verwenden",
      });
      */
      return state;
    },
    wTMMain() {
      let x = [];
      let mainIDs = [40, 42, 44, 46, 48, 50, 52, 53, 59, 61];
      for (let i in this.wageTypesMapping) {
        if (mainIDs.includes(this.wageTypesMapping[i].enumId)) {
          x.push(this.wageTypesMapping[i]);
        }
      }
      return x;
    },
    wTMNMain() {
      let x = [];
      let mainIDs = [40, 42, 44, 46, 48, 50, 52, 53, 59, 61];
      for (let i in this.wageTypesMapping) {
        if (!mainIDs.includes(this.wageTypesMapping[i].enumId)) {
          x.push(this.wageTypesMapping[i]);
        }
      }
      return x;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;
      view.loadEnumWageTypes();
    },

    loadEnumWageTypes: function () {
      let view = this;
      let url = "/api/enum/wagetype";
      this.axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          let data = response.data;
          let tmp = [];
          for (let i = 0; i < data.length; i++) {
            let item = {
              enumId: data[i].id,
              enumKey: data[i].key,
              enumValueGER: data[i].valueGER,
              enumValueENG: data[i].valueENG,
            };
            tmp.push(item);
          }
          view.wageTypesMapping = tmp.slice();
          view.loadWageTypesMapping();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    loadWageTypesMapping: function () {
      let view = this;
      //let params = { companyId: view.$store.state.companyId };
      let params = {};
      view.$helpers
        .GetCall("/api/sec/mapping/wagetype", params)
        .then((response) => {
          for (let i = 0; i < view.wageTypesMapping.length; i++) {
            view.wageTypesMapping[i].externalId = "";
            for (let j = 0; j < response.data.data.length; j++) {
              let tmp = response.data.data[j];
              if (parseInt(view.wageTypesMapping[i].enumId) == parseInt(tmp.wageTypeId)) {
                view.wageTypesMapping[i].id = tmp.id;
                view.wageTypesMapping[i].originalExternal = tmp.externalId;
                view.wageTypesMapping[i].externalId = tmp.externalId;
              }
            }
          }
          view.$forceUpdate();
        })
        .catch((e) => {
          view.loading = false;
          console.log(e);
        });
    },

    openEditWageType(wageType) {
      let view = this;
      let description = wageType.enumValueGER;

      let dataEntries = [
        {
          label: "id: ",
          key: "id",
          hidden: true,
          type: "text",
          value: wageType.enumId,
          length: 128,
          styles: { width: "350px" },
        },
        {
          label: "Nummer: ",
          key: "externalId",
          hidden: false,
          type: "numeric",
          value: wageType.externalId,
          styles: { width: "150px" },
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          description: description,
          selectCallback: function (x) {
            view.editWageType(x);
          },
          deleteCallback: function (x) {
            view.deleteWageTypeMapping(x);
          },
          errorCheck: {
            externalId: (entries) => {
              return null;
            },
          },
        },
        header: "Lohnart bearbeiten",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    editWageType(wageType) {
      let view = this;
      let url = "/api/sec/mapping/wagetype/" + wageType.id;
      let payload = {
        companyId: this.$store.state.companyId,
        externalId: wageType.externalId,
      };
      this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Lohnart geändert", "Lohnart geändert");
          view.refresh(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteWageTypeMapping(wageType) {
      let view = this;
      let url = "/api/sec/mapping/wagetype/" + wageType.id;
      let payload = {
        companyId: this.$store.state.companyId,
      };
      this.axios({
        method: "delete",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Gelöscht", "Lohnart Mapping wurde erfolgreich entfernt");
          view.refresh(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fillDefaultValues($event) {
      let view = this;
      let dataEntries = [
        {
          label: "",
          key: "number",
          hidden: false,
          type: "output",
          value: "Sollen wirklich alle Werte überschrieben werden?",
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          positiveButtonLabel: "Standard wiederherstellen",
          entries: dataEntries,
          selectCallback: function () {
            view.editWageType({ id: 0, externalId: 0 });
            view.editWageType({ id: 1, externalId: 0 });
            view.editWageType({ id: 2, externalId: 0 });
            view.editWageType({ id: 3, externalId: 0 });
            view.editWageType({ id: 4, externalId: 0 });
            view.editWageType({ id: 5, externalId: 0 });
            view.editWageType({ id: 6, externalId: 0 });
            view.editWageType({ id: 7, externalId: 0 });
            view.editWageType({ id: 8, externalId: 0 });
            view.editWageType({ id: 9, externalId: 0 });
            view.editWageType({ id: 10, externalId: 0 });
            view.editWageType({ id: 11, externalId: 0 });
            view.editWageType({ id: 12, externalId: 0 });
            view.editWageType({ id: 13, externalId: 0 });
            view.editWageType({ id: 14, externalId: 0 });
            view.editWageType({ id: 15, externalId: 0 });
            view.editWageType({ id: 16, externalId: 0 });
            view.editWageType({ id: 17, externalId: 0 });
            view.editWageType({ id: 18, externalId: 0 });
            view.editWageType({ id: 19, externalId: 0 });
            view.editWageType({ id: 20, externalId: 0 });
            view.editWageType({ id: 21, externalId: 0 });
            view.editWageType({ id: 22, externalId: 0 });
            view.editWageType({ id: 23, externalId: 0 });
            view.editWageType({ id: 24, externalId: 0 });
            view.editWageType({ id: 25, externalId: 0 });
            view.editWageType({ id: 26, externalId: 0 });
            view.editWageType({ id: 27, externalId: 0 });
            view.editWageType({ id: 28, externalId: 0 });
            view.editWageType({ id: 29, externalId: 0 });
            view.editWageType({ id: 30, externalId: 0 });
            view.editWageType({ id: 31, externalId: 0 });
            view.editWageType({ id: 32, externalId: 0 });
            view.editWageType({ id: 33, externalId: 0 });
            view.editWageType({ id: 34, externalId: 0 });
            view.editWageType({ id: 35, externalId: 0 });
            view.editWageType({ id: 36, externalId: 0 });
            view.editWageType({ id: 37, externalId: 0 });
            view.editWageType({ id: 38, externalId: 0 });
            view.editWageType({ id: 39, externalId: 0 });
            view.editWageType({ id: 40, externalId: 0 });
            view.editWageType({ id: 41, externalId: 0 });
            view.editWageType({ id: 42, externalId: 0 });
            view.editWageType({ id: 43, externalId: 0 });
            view.editWageType({ id: 44, externalId: 0 });
            view.editWageType({ id: 45, externalId: 0 });
            view.editWageType({ id: 46, externalId: 0 });
            view.editWageType({ id: 47, externalId: 0 });
            view.editWageType({ id: 48, externalId: 0 });
            view.editWageType({ id: 49, externalId: 0 });
            view.editWageType({ id: 50, externalId: 0 });
            view.editWageType({ id: 51, externalId: 0 });
          },
        },
        header: "Bestätigung erforderlich",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.xColumn3 {
  width: 40%;
  text-align: left;
  float: left;
  margin-left: 5%;
  margin-right: 5%;
  overflow-y: auto;
}
</style>
